<template>
<div class="">
    <slot v-if="widgetLoad">
        <div class="col-12 col-lg-12 col-xl-12 col-xxl-12">
            <div class="text-center p-2">
                <div class="widget-loading"></div>
            </div>
        </div>
    </slot>
    <slot v-else>


        <div class="row global-user-card mobile-global-user-card" v-if="analysts.data.length">
            <div class="tgju-widgets-block col-12 col-md-6 col-xl-3"  v-for="analyst in analysts.data" :key="analyst.id">
                <div class="tgju-widget light">
                    <div class="dashboard-user-card">
                        <div class="dashboard-user-card-avatar">
                            <img :src="analyst.avatar ? ($helpers.api_url() + $helpers.json_pars(analyst.avatar).name) : 'https://static.tgju.org/views/default/images/tgju-user-avatar.png'">
                        </div>

                        <router-link class="dashboard-user-card-title" :to="{ name: 'ProfileTechnicalOthers', params: { username: analyst.username }}">
                            <h3>{{ analyst.firstname +' '+ analyst.lastname }}</h3>
                        </router-link>

                        <div v-if="analyst.analyst_rating" class="star-row" style="max-width: 100px;margin: auto;">
                            <i v-for="index in analyst.analyst_rating" :key="index" class="fa fa-star" aria-hidden="true"></i>
                            <i v-for="index in (5 - analyst.analyst_rating)" :key="index" class="fa fa-star-o" aria-hidden="true"></i>
                        </div>

                        <div class="user-card-content">
                            <div class="user-card-content-item">
                                <i class="uil uil-comment-alt-lines"></i>
                                <div class="items-left-box">
                                    <span>{{ analyst.comments }}</span>
                                    <h3>دیدگاه‌ها</h3>
                                </div>
                            </div>
                            <div class="user-card-content-item">
                                <i class="uil uil-chart"></i>
                                <div class="items-left-box">
                                    <span>{{ analyst.posts }}</span>
                                    <h3>تحلیل‌ها</h3>
                                </div>
                            </div>
                            <div class="user-card-content-item">
                                <i class="uil uil-thumbs-up"></i>
                                <div class="items-left-box">
                                    <span>{{ analyst.likes }}</span> 
                                    <h3>لایک‌ها</h3>
                                </div>
                            </div>
                        </div>
                        <span class="dashboard-user-card-user-joined">
                            تاریخ عضویت: {{ toRelative(analyst.created_at) }}
                        </span>
                        <span class="dashboard-user-card-btn">
                            <router-link class="btn btn-secondary btn-round btn-large" :to="{ name: 'ProfileTechnicalOthers', params: { username: analyst.username }}">
                                <i class="fa fa-user" aria-hidden="true"></i>
                                پروفایل
                            </router-link>
                        </span>
                    </div> 
                </div>
            </div>
        </div>

        <div v-else class="noWidget noContent">
            <div>
                <i class="uil uil-chart noContent-icon" aria-hidden="true"></i>
                <p class="noContent-title">تحلیل گری برای نمایش وجود ندارد</p>
            </div>
        </div>
  
        
        <div v-if="analysts.last_page > 1" class="p-5 d-flex justify-content-center">
            <Paginate :page-count="analysts.last_page" :click-handler="getAnalysts" :prev-text="'قبل'" :next-text="'بعد'" :container-class="'pagination'"></Paginate>
        </div>
    </slot>
</div>
</template>

<style lang="scss" scoped>
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}
</style>

<script>
// این کامپوننت دارای تمام اجزای نمایش لیست عمومی تحلیل می باشد
import moment from "moment-jalaali";
import Paginate from "vuejs-paginate";
// import DropdownMenu from '@innologica/vue-dropdown-menu';

export default {
    name: 'PublicAnalysis',
    props: ['filter', 'hot', 'time'],
    components: {
        // 'dropdown-menu': DropdownMenu,
        Paginate

    },
    data: function () {
        return {
            analysts: [],
            widgetLoad: true,
        }
    },
    mounted() {
        // دریافت اطلاعات تحلیل های تایید شده ی عمومی
        this.getAnalysts();
    },
    methods: {
        // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
        toRelative(date) {
            return moment(date).fromNow();
        },
        // این متد جهت ارسال درخواست به وب سرویس جهت دریافت لیست تحلیل های کاربران می باشد
        getAnalysts(page) {
            let api_data = {
                page: page ? page : 1,
                filter: this.filter ? this.filter : 'newest',
                hot: this.hot ? this.hot : 'false',
                time: this.time ? this.time : 'all',
            };

            // ارسال در خواست به وب سرویس
            this.$helpers.makeRequest('GET', '/technical/analysts', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);
                if (api_response.status == 200) {
                    var newArray = api_response.data.response.users;
                    this.analysts = newArray;
                    this.widgetLoad = false;
                    this.$parent.$refs.top.scrollIntoView({block: "end"});
                } 
            });
        }
    },
}
</script>
